import React from 'react'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { TrustPilotCarouselWidget } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  TrustBox,
  TrustBoxProps,
} from '@thg-commerce/enterprise-widget-trustpilot'

export type TrustPilotCarouselRendererProps = Omit<
  TrustPilotCarouselWidget,
  'query'
>

export const TrustPilotCarouselRenderer = (
  props: TrustPilotCarouselRendererProps,
) => {
  const { trustPilot } = useSiteConfig()

  if (
    (!trustPilot?.enableTrustPilot && !trustPilot?.enableTrustBox) ||
    !props.businessId ||
    !props.templateId ||
    !props.locale
  ) {
    return null
  }

  const trustBoxProps: TrustBoxProps = {
    businessId: props.businessId,
    templateId: props.templateId,
    locale: props.locale,
    height: props.height || undefined,
    link: props.url || undefined,
    trustBoxTheme: props.theme || undefined,
    width: props.width || undefined,
    reviewLanguageCode: props.languageCode || undefined,
    dataStars: props.dataStars || undefined,
  }

  return <TrustBox {...trustBoxProps} />
}
